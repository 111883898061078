import { useState, useEffect } from "react";

export default function useRefreshableRequest({ request, onError, defaultDataValue = null }) {
  const [isLoading, setIsLoading] = useState(false);
  const [lastRequestTime, setLastRequestTime] = useState(Date.now()); // used to trigger new request
  const [requestData, setRequestData] = useState(defaultDataValue);

  const triggerRequest = async () => {
    if (!isLoading) {
      setIsLoading(true);
      try {
        const data = await request();
        setRequestData(data);
      } catch (e) {
        if (onError) onError(e);
      }
      setIsLoading(false);
    }
  }

  useEffect(() => {
    triggerRequest();
  }, [request, lastRequestTime]);

  return {
    data: requestData,
    refreshData: () => setLastRequestTime(Date.now()),
    isLoading,
  };
}
