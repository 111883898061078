import React, {useContext, useEffect, useState} from "react";
import { Button, Form } from "react-bootstrap";
import {
  ClueMasterPageLayout,
  CLUE_MASTER_NAVIGATION_ACTIVE_KEYS,
  ClueView,
  FormGroup,
  LoadingSpinner,
} from "../../components/components";
import {getAllMasterClues} from "../../services/firebase";
import useRefreshableRequest from "../../hooks/useRefreshableRequest";
import CluePreviewHints from "../../app/clue-master/clue-preview/CluePreviewHints";
import appContent from "../../markdown/app-content";
import {AppAlertsContext} from "../../context/app-alerts-context";

const CluePreviewPage = () => {
  const [selectedClue, setSelectedClue] = useState(null);
  const [selectedChallenge, setSelectedChallenge] = useState('');
  const {popError} = useContext(AppAlertsContext);
  const {data, refreshData, isLoading} = useRefreshableRequest({
    request: getAllMasterClues,
    onError: (e) => popError(e.message),
    defaultDataValue: [],
  });
  const clues = data?.clues || [];
  const challengeNames = data?.challengeNames || [];
  const clueAnswers = selectedClue?.answers || [];
  const filteredClues = clues.filter(c => !selectedChallenge || !!c.challenge?.find(name => name === selectedChallenge));

  useEffect(() => {
    if (selectedClue) {
      setSelectedClue(
        clues.find(c => c.id === selectedClue.id)
      );
    }
  }, [clues]);

  // Reset selected clue if challenge filter was changed to one that doesn't inclue it
  useEffect(() => {
    if (
      selectedChallenge &&
      selectedClue &&
      !selectedClue.challenge?.find(name => name === selectedChallenge)
    ) {
      setSelectedClue(null);
    }
  }, [selectedClue, selectedChallenge]);

  const handleClueChange = (event) => {
    const masterClueID = event.target.value;
    setSelectedClue(clues.find(c => c.id === masterClueID));
  }

  const handleChallengeChange = (event) => {
    const challengeName = event.target.value;
    setSelectedChallenge(challengeName);
  }

  return <ClueMasterPageLayout activeID={CLUE_MASTER_NAVIGATION_ACTIVE_KEYS.cluePreview}>
    <LoadingSpinner isLoading={isLoading}>
      <div className="d-flex justify-content-between align-items-center">
        <h1>{appContent.cluemaster.cluePreview.title}</h1>
        <Button onClick={refreshData}>{appContent.cluemaster.cluePreview.refreshButton}</Button>
      </div>
      {clues && (
        <Form>
          <FormGroup
            label={appContent.cluemaster.cluePreview.labels.filterByChallenge}
            subText={appContent.cluemaster.cluePreview.subText.filterByChallenge}
            controlId="cluePreview.filterByChallenge"
          >
            <Form.Control
              as="select"
              onChange={handleChallengeChange}
              value={selectedChallenge}
            >
              <option value=""> </option>
              {challengeNames.map(name => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </Form.Control>
          </FormGroup>

          <FormGroup
            label={appContent.cluemaster.cluePreview.labels.clue}
            subText={appContent.cluemaster.cluePreview.subText.clue}
          >
            <Form.Control
              as="select"
              onChange={handleClueChange}
              value={selectedClue?.id}
            >
              <option value=""> </option>
              {filteredClues.map(clue => (
                <option key={clue.id} value={clue.id}>
                  {clue.name}
                </option>
              ))}
            </Form.Control>
          </FormGroup>
        </Form>
      )}
      {selectedClue && <>
        <ClueView clue={selectedClue} />
        <dl>
          <dt>{appContent.cluemaster.cluePreview.answersSectionTitle}</dt>
          {clueAnswers.map(answer => (
            <dd className="mb-0" key={answer}>
              {answer}
            </dd>
          ))}
        </dl>
      </>}
      {selectedClue && <>
        <h1 className="h2">{appContent.cluemaster.cluePreview.hintsSectionTitle}</h1>
        <CluePreviewHints clue={selectedClue} />
      </>}
    </LoadingSpinner>
  </ClueMasterPageLayout>
}

export default CluePreviewPage;
