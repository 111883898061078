import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import Clue from "../../../entities/clue";
import {getMasterHints} from "../../../services/firebase";
import {AppAlertsContext} from "../../../context/app-alerts-context";
import {ListGroup, ListGroupItem, LoadingSpinner, Markdown} from "../../../components/components";

const CluePreviewHints = ({clue}) => {
  const { popError } = useContext(AppAlertsContext);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedHints, setFetchedHints] = useState([]);

  useEffect(async () => {
    if (clue) {
      if (clue.hintIds.length > 0) {
        setIsLoading(true);
        try {
          const hints = await getMasterHints(clue.hintIds);
          setFetchedHints(hints)
        } catch (e) {
          popError(e.message);
        }
        setIsLoading(false);
      } else {
        setFetchedHints([]);
      }
    } else {
      setFetchedHints([]);
    }

    return () => setIsLoading(false);
  }, [clue]);

  return <LoadingSpinner isLoading={isLoading} hideChildrenWhenLoading>
    {fetchedHints.length === 0 && <ListGroup>
      <ListGroupItem>No hints</ListGroupItem>
    </ListGroup>}
    <ListGroup>
      {fetchedHints.map(hint => <ListGroupItem
        key={hint.id}
        title={hint.isWalkthrough ? 'Walkthrough' : <Markdown>{hint.question}</Markdown>}
      >
        <Markdown>{hint.hint}</Markdown>
      </ListGroupItem>)}
    </ListGroup>
  </LoadingSpinner>
};

CluePreviewHints.propTypes = {
  clue: PropTypes.instanceOf(Clue),
};

export default CluePreviewHints;
